import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

const BodyText = styled.span`
  display: flex;
  flex-direction: column;
  margin-top: ${svars.spaceNormalLarge};
  margin-left: ${svars.spaceLarge};
  font-size: ${({ largeFont: islargefont }) =>
    islargefont ? svars.fontSizeMedium : svars.fontSizeBiggerBase};
  color: ${({ isRead: isread }) =>
    isread ? svars.colorGrey : svars.colorBlack};
`;

const CustomIcon = styled(Icon)`
  color: ${({ customColor: customcolor }) => customcolor};
`;

const userAssignmentIcons = {
  campaignName: {
    name: svars.campaignIcon,
    color: svars.campaignColor,
  },
  analysisName: {
    name: svars.analysisIcon,
    color: svars.analysisColor,
  },
};

function PresetUserNotification({ isRead, preset }) {
  return (
    <BodyText isRead={isRead}>
      <span>
        <Icon name="bookmark" />
        {preset.view_facet?.name?.trim()}
      </span>
    </BodyText>
  );
}
PresetUserNotification.propTypes = {
  isRead: PropTypes.bool.isRequired,
  preset: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string.isRequired,
    view_facet: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

function CampaignPresetUserNotification({ isRead, preset }) {
  return (
    <BodyText isRead={isRead}>
      <span>
        <Icon name="bookmark" />
        {preset.name.trim()}
      </span>
    </BodyText>
  );
}
CampaignPresetUserNotification.propTypes = {
  isRead: PropTypes.bool.isRequired,
  preset: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

function UserAssignment({
  isRead,
  campaignName,
  analysisName,
  annotationField,
  oldValue,
  newValue,
}) {
  const iconType = campaignName
    ? userAssignmentIcons.campaignName
    : userAssignmentIcons.analysisName;
  return (
    <>
      <BodyText isRead={isRead}>
        <CustomIcon
          name={iconType.name}
          customColor={iconType.color}
          size="large"
        />
        {annotationField} - {campaignName || analysisName}
      </BodyText>
      <BodyText largeFont isRead={isRead}>
        {oldValue} → {newValue}
      </BodyText>
    </>
  );
}
UserAssignment.propTypes = {
  isRead: PropTypes.bool.isRequired,
  campaignName: PropTypes.string.isRequired,
  analysisName: PropTypes.string.isRequired,
  annotationField: PropTypes.string.isRequired,
  oldValue: PropTypes.string.isRequired,
  newValue: PropTypes.string.isRequired,
};

const bodyComponents = {
  PresetUserNotification,
  CampaignPresetUserNotification,
  user_asignment: UserAssignment,
};

function NotificationBody({ type, isRead, ...otherProps }) {
  const BodyComponent = bodyComponents[type];
  return <BodyComponent isRead={isRead} {...otherProps} />;
}

NotificationBody.propTypes = {
  type: PropTypes.oneOf([
    'PresetUserNotification',
    'CampaignPresetUserNotification',
  ]).isRequired,
  details: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      campaignName: PropTypes.string,
      source: PropTypes.string,
      analysisName: PropTypes.string,
      annotationField: PropTypes.string,
      oldValue: PropTypes.string,
      newValue: PropTypes.string,
      preset: PropTypes.shape({ name: PropTypes.string }),
    }),
  ]).isRequired,
  isRead: PropTypes.bool.isRequired,
};

export default NotificationBody;
