import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Card, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';
import { analysisIcon, campaignIcon } from 'assets/style/variables';

const TitleWrapper = styled(Card.Header)`
  display: flex;
  align-items: center;
  max-width: 90%;
  justify-content: space-between;
  line-height: normal;
  padding-bottom: 4px;
`;

const TitleText = styled.span`
  margin-left: ${svars.spaceNormal};
  flex-grow: 1;
  overflow: hidden;
  text-overflow: wrap;
  color: ${(props) => (props.isRead ? svars.colorGrey : svars.colorBlack)};
  font-weight: ${svars.fontWeightMedium};
  font-size: ${svars.fontSizeLarge};
`;

const IconContainer = styled.div`
  width: ${svars.spaceMediumLarge};
  height: ${svars.spaceMediumLarge};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomIcon = styled(Icon)`
  color: ${(props) => (props.isRead ? svars.colorGrey : svars.colorBlack)};
`;

function PresetUserNotification({ isRead, preset }) {
  return (
    <TitleText isRead={isRead}>
      <Trans>
        {preset.notification?.current_statistics?.n_chunks} new extracts have
        been collected in
      </Trans>{' '}
      <span style={{ fontWeight: svars.fontWeightExtraBold }}>
        {preset.view_facet.name}
      </span>
    </TitleText>
  );
}
PresetUserNotification.propTypes = {
  isRead: PropTypes.bool.isRequired,
  preset: PropTypes.shape({
    name: PropTypes.string.isRequired,
    view_facet: PropTypes.shape({ name: PropTypes.string }),
    notification: PropTypes.shape({
      current_statistics: PropTypes.shape({ n_chunks: PropTypes.number }),
    }),
  }).isRequired,
};

function CampaignPresetUserNotification({ isRead, n_new_reviews, preset }) {
  const nNewResponses = n_new_reviews;
  const campaignName = preset.campaign?.name;
  return (
    <TitleText isRead={isRead}>
      <span>
        <Trans>{nNewResponses} new responses collected in</Trans>{' '}
      </span>
      <span style={{ fontWeight: svars.fontWeightExtraBold }}>
        {campaignName}
      </span>
    </TitleText>
  );
}
CampaignPresetUserNotification.propTypes = {
  isRead: PropTypes.bool.isRequired,
  n_new_reviews: PropTypes.string.isRequired,
  preset: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string.isRequired,
    campaign: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

function UserAssignment({ isRead, ...otherProps }) {
  return (
    <TitleText isRead={isRead}>
      <Trans>
        {otherProps?.source} assigned a response to you in [
        {otherProps?.campaignName || otherProps?.analysisName}]
      </Trans>
    </TitleText>
  );
}
UserAssignment.propTypes = {
  isRead: PropTypes.bool.isRequired,
};

const titleComponents = {
  PresetUserNotification,
  CampaignPresetUserNotification,
};

const typeIcons = {
  PresetUserNotification: analysisIcon,
  CampaignPresetUserNotification: campaignIcon,
};

const typeKeys = Object.keys(typeIcons);

function NotificationTitle({ type, isRead, ...otherProps }) {
  const TitleComponent =
    titleComponents[type] ||
    (() => <TitleText isRead={isRead}>{otherProps}</TitleText>);
  return (
    <TitleWrapper>
      <IconContainer>
        <CustomIcon name={typeIcons[type]} size="small" isRead={isRead} />
      </IconContainer>
      <TitleComponent isRead={isRead} {...otherProps} />
    </TitleWrapper>
  );
}

NotificationTitle.propTypes = {
  type: PropTypes.oneOf(typeKeys).isRequired,
  isRead: PropTypes.bool.isRequired,
};

export default NotificationTitle;
